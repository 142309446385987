<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('RoleCreate')"
    @edit="(id) => onEdit('RoleEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Roles',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Roles',
      tableCaption: 'Роли сотрудников',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Роли сотрудников',
        },
      ],
      tableHeaders: [
        {
          text: 'Наименование',
          alias: 'name',
          order: 'name',
        },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
